import Vue from 'vue'
import App from './App.vue'
import {router} from "@/router/router"
import axios from 'axios'
import i18n from './i18n'
import "./assets/css/tailwind.css"
import store from './store'

localStorage.theme = "light"

import VCalendar from 'v-calendar'

Vue.use(VCalendar);
Vue.config.productionTip = false;

Vue.prototype.$axios = axios;
Vue.prototype.$langg = i18n;
Vue.prototype.$panel = "https://admin.inonu.edu.tr/";

new Vue({
    store,
    i18n,
    router,
    render: h => h(App)
}).$mount('#app');

i18n.locale = 'tr'

Vue.filter('dateFormat', function (value) {
    if (value) {
        const date = new Date(value.toString())
        return date.toLocaleDateString(['tr-TR'], {month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})
    }
});

Vue.filter('dateFormat2', function (value) {
    if (value) {
        const date = new Date(value.toString())
        return date.toLocaleDateString(['tr-TR'], {month: 'short', day: '2-digit', year: 'numeric'})
    }
});

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
});

Vue.filter('slugify', function (value) {
    value = value.replace(/^\s+|\s+$/g, ''); // trim
    value = value.toLowerCase();

    let from = "ãàáäâẽèéëêìíïîõòóöôùúüûñçışğ·/_,:;";
    let to = "aaaaaeeeeeiiiiooooouuuuncisg------";
    for (let i = 0, l = from.length; i < l; i++) {
        value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    value = value.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return value;
});

Vue.filter('translate', function (value, module, type) {
    if (value[module] && value[module][i18n.locale]) {
        return value[module][i18n.locale][type];
    } else {
        return value[module]['tr'][type];
    }
});

Vue.prototype.$setCookie = function (c_name, c_value, ex_days) {
    let d = new Date();
    d.setTime(d.getTime() + (ex_days * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = c_name + "=" + c_value + ";" + expires + ";path=/";
};

Vue.prototype.$getCookie = function (c_name) {
    let name = c_name + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/*axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (!error.response) {
            let proceed = confirm("İnternet bağlantınızı kontrol edin!");
            if (proceed) {
                window.location.reload(true);
            } else {
                console.log("ok");
            }
        }
        return Promise.reject(error)
    }
)*/

