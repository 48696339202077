import axios from 'axios'
import Vuex from 'vuex'
import Vue from 'vue'
import i18n from './i18n'

Vue.use(Vuex);
const panel = "https://admin.inonu.edu.tr/";
const state = {
    homeAnnouncements: [],
    homeEvents: [],
    menus: [],
    carousels: [],
    homePosts: []
}
const mutations = {
    initAnnouncements(state, announcements) {
        state.homeAnnouncements = announcements
    },
    initHomeEvents(state, homeEvents) {
        state.homeEvents = homeEvents
    },
    initMenus(state, menus) {
        state.menus = menus
    },
    initCarousels(state, carousels) {
        state.carousels = carousels
    },
    initHomePosts(state, homePosts) {
        state.homePosts = homePosts
    }
}
const actions = {
    announcementApp(context) {
        axios.get(panel + 'servlet/announcement?type=home&count=6&lang=' + i18n.locale).then(response => {
            context.commit("initAnnouncements", response.data)
        }).catch(error => console.log(error))
    },
    eventApp(context, date) {
        axios.get(panel + 'servlet/event?type=home&date=' + date).then(response => {
            context.commit("initHomeEvents", response.data)
        }).catch(error => console.log(error));
    },
    menuApp(context, p) {
        axios.get(panel + 'servlet/menu?type=' + p.type + '&position=' + p.position + '&unit=' + p.unit).then(response => {
            context.commit("initMenus", response.data)
        }).catch(error => console.log(error))
    },
    carouselApp(context, p) {
        axios.get(panel + 'servlet/carousel?unit=' + p.unit + '&lang=' + p.lang).then(response => {
            context.commit("initCarousels", response.data)
        }).catch(error => console.log(error))
    },
    postApp(context) {
        axios.get(panel + 'servlet/post?type=home&unit=inuhaber&count=8').then(response => {
            context.commit("initHomePosts", response.data)
        }).catch(error => console.log(error))
    },
}
const getters = {
    getAnnouncements(state) {
        return state.homeAnnouncements
    },
    getHomeEvents(state) {
        return state.homeEvents
    },
    getMenus(state) {
        return state.menus
    },
    getCarousels(state) {
        return state.carousels
    },
    getHomePosts(state) {
        return state.homePosts
    },
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})
