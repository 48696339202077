import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter);

export const router = new VueRouter(
    {
        routes: [
            {
                path: "/",
                component: () => import('@/components/Home/HomeApp'),
                /*components: {
                    header: Header,
                    default: () => import('@/components/Home/HomeApp'),
                    footer: Footer
                },*/
                children: [
                    {
                        path: "search/:term",
                        name: "Search",
                        component: () => import('@/components/Home/Search'),
                        props: true
                    },
                    {
                        path: "hata/:type",
                        name: "Error",
                        component: () => import('@/components/Common/Error')
                    },
                    {
                        path: "",
                        name: "Home",
                        component: () => import('@/components/Home/Home')
                    },
                    {
                        path: "duyurular",
                        name: "AnnouncementHomeList",
                        component: () => import('@/components/Announcement/Home/AnnouncementHomeList')
                    },
                    {
                        path: "yemek",
                        name: "Food",
                        component: () => import('@/components/Food/FoodList')
                    },
                    {
                        path: "ara/personel",
                        name: "StaffList",
                        component: () => import('@/components/Home/Staff')
                    },
                    {
                        path: "duyuru/:announcementId/:slug",
                        name: "HomeAnnouncementDetail",
                        component: () => import('@/components/Announcement/Common/HomeAnnouncementDetail')
                    },
                    {
                        path: "etkinlikler",
                        name: "Events",
                        component: () => import('@/components/Event/EventList')
                    },
                    {
                        path: "etkinlik/:eventId/:slug",
                        name: "EventDetail",
                        component: () => import('@/components/Event/EventDetail')
                    },
                    {
                        path: "menu/:menuId/:slug",
                        name: "Content",
                        component: () => import('@/components/Common/Content')
                    },
                    {
                        path: "ihale",
                        name: "Tender",
                        component: () => import('@/components/Tender/Tender')
                    },
                    {
                        path: "iletisim",
                        name: "Contact",
                        component: () => import('@/components/Common/Contact')
                    },
                    {
                        path: "mevzuat",
                        name: "Legislation",
                        component: () => import('@/components/Legislation/LegislationList')
                    },
                    {
                        path: "mevzuat/:legislationId/:slug",
                        name: "LegislationDetail",
                        component: () => import('@/components/Legislation/LegislationDetail')
                    },
                ]
            },
            {
                path: "/:unit",
                component: () => import('@/components/Templates/CMSApp'),
                /*components: {
                    header: CMSHeader,
                    default: () => import('@/components/Templates/CMSApp'),
                    footer: CMSFooter
                },*/
                children: [
                    {
                        path: "",
                        name: "CMSHome",
                        component: () => import('@/components/Templates/CmsHome')
                    },
                    {
                        path: "menu/:menuId/:slug",
                        name: "CMSContent",
                        component: () => import('@/components/Common/Content')
                    },
                    {
                        path: "duyuru/:announcementId/:slug",
                        name: "CMSAnnouncementDetail",
                        component: () => import('@/components/Announcement/Common/CMSAnnouncementDetail')
                    },
                    {
                        path: "haber/:postId/:slug",
                        name: "CMSPostDetail",
                        component: () => import('@/components/Post/Common/PostDetail')
                    },
                    {
                        path: "haberler",
                        name: "CMSPosts",
                        component: () => import('@/components/Post/Common/PostList')
                    },
                    {
                        path: "duyurular",
                        name: "CMSAnnouncements",
                        component: () => import('@/components/Announcement/Common/AnnouncementList')
                    },
                    {
                        path: "personel",
                        name: "Staff",
                        component: () => import('@/components/Templates/Staff')
                    },
                    {
                        path: "akademisyen",
                        name: "Academicians",
                        component: () => import('@/components/Templates/Academicians')
                    },
                    {
                        path: "iletisim",
                        name: "CMSContact",
                        component: () => import('@/components/Common/Contact')
                    },
                    {
                        path: "veritabanlari",
                        name: "LibraryDatabase",
                        component: () => import('@/components/Templates/LIBRARY/LibraryDatabase')
                    },
                    {
                        path: "egitim/:educationId/:slug",
                        name: "EducationDetail",
                        component: () => import('@/components/Education/EducationDetail')
                    }
                ]
            },
            {
                path: "/akademik",
                component: () => import('@/components/Academic/AcademicApp'),
                children: [
                    {
                        path: ":user",
                        name: "Academic",
                        component: () => import('@/components/Academic/Academic'),
                        meta: {requiresAuth: true}
                    },
                    {
                        path: ":user/menu/:menuId/:slug",
                        name: "AcademicContent",
                        component: () => import('@/components/Academic/AcademicContent')
                    },
                ]
            },
            {
                path: "*",
                redirect: "/"
            }
        ],
        mode: "history",
        scrollBehavior: to => {
            if (to.hash) {
                return {selector: to.hash};
            } else {
                return {x: 0, y: 0};
            }
        }
    }
);
router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload()
    }
})
